import { AssetAmount } from '@moonbeam-network/xcm-types';
import { getBalance } from '@wagmi/core';
import { useAddress } from 'hooks/useAddress';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TokenBalance } from 'types/assets';
import { Address } from 'viem';
import { useConfig } from 'wagmi';
import { importedTokensAtom } from './atoms';
import type { ImportedToken } from './interfaces';

interface UseImportedTokensResponse {
  addImportedToken: (tokenInfo: ImportedToken) => Promise<boolean>;
  isTokenImported: (contractAddress: string) => boolean;
  importedTokens: ImportedToken[];
  removeImportedToken: (contractAddress: string) => void;
}

export function useImportedTokens(): UseImportedTokensResponse {
  const network = useMoonChainKeyParam();
  const address = useAddress();
  const config = useConfig();
  const [importedTokens, setImportedTokens] =
    useRecoilState(importedTokensAtom);

  const addImportedToken = useCallback(
    async (tokenInfo: ImportedToken) => {
      // Check if it's a valid contract by trying to fetch balance
      try {
        await getBalance(config, {
          address: address as Address,
          token: tokenInfo.contractAddress,
        });

        setImportedTokens((prevImports) => {
          if (
            prevImports[network].some(
              (x) => x.contractAddress === tokenInfo.contractAddress,
            )
          )
            return prevImports;

          return {
            ...prevImports,
            [network]: [...prevImports[network], tokenInfo],
          };
        });

        return true;
      } catch (error) {
        return false;
      }
    },
    [setImportedTokens, network, address, config],
  );

  const isTokenImported = useCallback(
    (contractAddress: string) =>
      importedTokens[network].some(
        (x) => x.contractAddress === contractAddress,
      ),
    [importedTokens, network],
  );

  const removeImportedToken = useCallback(
    (address: string) => {
      setImportedTokens((prevImports) => {
        return {
          ...prevImports,
          [network]: prevImports[network].filter(
            (x) => x.contractAddress !== address,
          ),
        };
      });
    },
    [setImportedTokens, network],
  );

  return {
    addImportedToken,
    isTokenImported,
    importedTokens: importedTokens[network],
    removeImportedToken,
  };
}

export function useImportedTokensBalance(): TokenBalance[] | undefined {
  const address = useAddress();
  const network = useMoonChainKeyParam();
  const config = useConfig();
  const { name } = useMoonChain();
  const importedTokens = useRecoilValue(importedTokensAtom);
  const [balances, setBalances] = useState<TokenBalance[] | undefined>();

  useEffect(() => {
    if (!address) return;

    const tokens = importedTokens[network] || [];

    Promise.all(
      tokens?.map((token) =>
        getBalance(config, {
          address: address as Address,
          token: token.contractAddress,
        }),
      ),
    ).then((responses) =>
      setBalances(
        responses.map(({ decimals, symbol, value }, index) => ({
          balance: new AssetAmount({
            amount: value,
            decimals,
            key: symbol.toLocaleLowerCase(),
            originSymbol: symbol,
            symbol,
          }),
          coinGeckoId: tokens[index].coinGeckoId,
          erc20Id: tokens[index].contractAddress,
          isImported: true,
          isNative: false,
        })),
      ),
    );
  }, [importedTokens, network, address, name, config]);

  return balances;
}
