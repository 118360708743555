import { Grid } from '@mantine/core';
import { useIsMounted } from 'hooks/useIsMounted';
import { Balance } from './Balance';
import { StakingRewards } from './StakingRewards';

export interface Props {
  address: string | undefined;
  isStakingPage?: boolean;
  showHistoricalRewardRate?: boolean;
}

export const BalanceGrid = ({
  address,
  isStakingPage,
  showHistoricalRewardRate,
}: Props) => {
  const isMounted = useIsMounted();

  if (!isMounted) return null;

  return (
    <Grid>
      <Grid.Col span={{ sm: 12, md: 12, lg: 5, xl: 4 }}>
        <Balance address={address} isStakingPage={isStakingPage} />
      </Grid.Col>
      <Grid.Col span={{ sm: 12, md: 12, lg: 7, xl: 8 }}>
        <StakingRewards
          address={address}
          showHistoricalRewardRate={showHistoricalRewardRate}
        />
      </Grid.Col>
    </Grid>
  );
};
