import { Box, Divider, Group, Text } from '@mantine/core';
import { MoonTokenAmount } from 'components/TokenAmount';
import { useBlockTimestampElapsedTime } from 'hooks/useBlockTime';
import { SubscanReward } from 'services/subscan';

interface Props {
  reward: SubscanReward;
  index: number;
}

export const StakingRewardRow = ({ reward, index }: Props) => {
  const elapsedTime = useBlockTimestampElapsedTime(reward.block_timestamp);
  const isFirst = index === 0;

  return (
    <Box>
      {!isFirst && <Divider mr={'xs'} />}
      <Group justify={'space-between'} pb={'xs'} pt={isFirst ? 0 : 'xs'}>
        <MoonTokenAmount
          amount={reward.amount}
          size={'sm'}
          maxDecimals={4}
          isHidable={true}
        />
        <Text fw={'lighter'} size={'xs'} mr={'xs'}>
          {elapsedTime}
        </Text>
      </Group>
    </Box>
  );
};
