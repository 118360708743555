import {
  aca,
  aseed,
  astr,
  bnc,
  cfg,
  csm,
  dai,
  dot,
  eq,
  fil,
  glmr,
  hdx,
  ibtc,
  intr,
  kar,
  kbtc,
  kint,
  kma,
  ksm,
  ldot,
  lit,
  manta,
  mgx,
  moonbeam,
  moonriver,
  movr,
  nodl,
  para,
  pen,
  pha,
  pica,
  ring,
  rmrk,
  sdn,
  sub,
  teer,
  tnkr,
  tur,
  usdc,
  usdcwh,
  usdt,
  usdtwh,
  vastr,
  vdot,
  vglmr,
  vksm,
  vmanta,
  vmovr,
  wbtc,
  weth,
  xrt,
  ztg,
} from '@moonbeam-network/xcm-config';

export const coinGeckoIds = {
  [moonbeam.key]: {
    [aca.key]: 'acala',
    [astr.key]: 'astar',
    [aseed.key]: 'acala-dollar-acala',
    [bnc.key]: 'bifrost-native-coin',
    [cfg.key]: 'centrifuge',
    [dai.key]: 'dai',
    [dot.key]: 'polkadot',
    [eq.key]: 'equilibrium-token',
    [hdx.key]: 'hydradx',
    [fil.key]: 'filecoin',
    [glmr.key]: 'moonbeam',
    [intr.key]: 'interlay',
    [ldot.key]: 'liquid-staking-dot',
    [manta.key]: 'manta-network',
    [nodl.key]: 'nodle-network',
    [para.key]: 'parallel-finance',
    [pen.key]: 'pendulum-chain',
    [pha.key]: 'pha',
    [ring.key]: 'darwinia-network-native-token',
    [sub.key]: 'subsocial',
    [usdc.key]: 'usd-coin',
    [usdcwh.key]: 'usd-coin-wormhole-from-ethereum',
    [usdt.key]: 'tether',
    [usdtwh.key]: 'tether-usd-wormhole',
    [wbtc.key]: 'wrapped-btc-wormhole',
    [weth.key]: 'ethereum-wormhole',
    [ibtc.key]: 'interbtc',
    [vastr.key]: 'bifrost-voucher-astr',
    [vdot.key]: 'voucher-dot',
    [vglmr.key]: 'voucher-glmr',
    [vmanta.key]: 'bifrost-voucher-manta',
    [ztg.key]: 'zeitgeist',
  },
  [moonriver.key]: {
    [aseed.key]: 'acala-dollar',
    [csm.key]: 'crust-storage-market',
    [kar.key]: 'karura',
    [kbtc.key]: 'kintsugi-btc',
    [kint.key]: 'kintsugi',
    [kma.key]: 'calamari-network',
    [ksm.key]: 'kusama',
    [lit.key]: 'lit',
    [mgx.key]: 'mangata-x',
    [movr.key]: 'moonriver',
    [rmrk.key]: 'rmrk',
    [sdn.key]: 'shiden',
    [teer.key]: 'integritee',
    [tnkr.key]: 'tinkernet',
    [tur.key]: 'turing-network',
    [usdt.key]: 'tether',
    [xrt.key]: 'robonomics-network',
    [pha.key]: 'pha',
    [pica.key]: 'picasso',
    [bnc.key]: 'bifrost-native-coin',
    [vksm.key]: 'voucher-ksm',
    [vmovr.key]: 'voucher-movr',
  },
};
